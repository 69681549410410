<template>
  <v-container>
    <title-header-action
      :hasActionPermission="canRegisterDonator"
      btnLabel="Cadastrar Doador"
      :btnAction="goToAddDonator"
    />

    <v-tabs
      active-class="tab-active"
      hide-slider
      background-color="transparent"
      v-model="activeTab"
    >
      <v-tab
        class="text-capitalize primary-color-2--text"
        v-for="item in items"
        :key="item"
      >
        {{ item }}
      </v-tab>
      <v-tabs-items v-model="activeTab">
        <v-tab-item>
          <donator-pj v-if="activeTab === TABS.PJ" />
        </v-tab-item>
        <v-tab-item>
          <donator-pf v-if="activeTab === TABS.PF" />
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
  </v-container>
</template>
<script>
  import { mapGetters } from "vuex";

  import DonatorPF from "@/modules/donator/views/components/DonatorPF";
  import DonatorPJ from "@/modules/donator/views/components/DonatorPJ";
  import TitleHeaderAction from "@/modules/core/views/components/TitleHeaderAction";

  export default {
    name: "Donators",

    components: {
      "donator-pf": DonatorPF,
      "donator-pj": DonatorPJ,
      TitleHeaderAction
    },

    data: () => ({
      activeTab: null,
      TABS: {
        PJ: 0,
        PF: 1
      },
      items: ["Doadores Pessoa Jurídica", "Doadores Pessoa Física"]
    }),

    computed: {
      ...mapGetters({
        userRoleId: "user/getUserRoleId",
        routes: "getRoutes"
      }),

      canRegisterDonator() {
        return this.routes?.some(
          route =>
            route.name === "new-donator" &&
            route?.meta?.permissions.includes(this.userRoleId)
        );
      }
    },

    methods: {
      goToAddDonator() {
        this.$router.push({ name: "new-donator" });
      }
    }
  };
</script>
<style lang="scss" scoped>
  @import "@/assets/stylesheets/styleguide";
  @import "@/assets/stylesheets/components";

  .tab-active {
    background: white !important;
  }

  .v-tab {
    border-radius: 6px 6px 0 0;
    &[aria-selected="false"] {
      background: rgba(255, 255, 255, 0.12);
      color: white !important;
    }
  }
</style>
