<template>
  <v-menu transition="slide-x-transition" bottom right>
    <template v-slot:activator="{ on, attrs }">
      <v-icon v-bind="attrs" v-on="on">
        mdi-dots-horizontal
      </v-icon>
    </template>
    <v-list>
      <v-list-item
        @click="goToDetailDonator"
        class="d-flex align-center item-dropdown-user"
      >
        <v-list-item-title>
          <v-icon class="item-dropdown-icon">mdi-account</v-icon
          ><span class="pl-2">Ver perfil</span>
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
  import { donatorTypes } from "../../enums/donatorTypes.enum";

  export default {
    name: "DonatorMenuItem",

    props: {
      id: {
        type: Number,
        required: true
      },
      type: {
        type: String,
        required: true,
        validator(value) {
          return [donatorTypes.PF, donatorTypes.PJ].includes(value);
        }
      }
    },

    methods: {
      goToDetailDonator() {
        const name =
          this.type === donatorTypes.PF
            ? "detail-donator-pf"
            : "detail-donator-pj";
        return this.$router.push({
          name,
          params: { id: this.id }
        });
      }
    }
  };
</script>

<style lang="scss" scoped></style>
