<template>
  <v-card class="pt-7">
    <div class="table-page d-flex flex-column justify-space-between">
      <div>
        <!--filters -->
        <template v-if="showFilters">
          <div class="row px-5 d-flex justify-end">
            <v-col cols="9" sm="8" md="3">
              <v-text-field
                @input="setFilterDebounced('search', $event)"
                solo
                flat
                full-width
                color="secondary-color-1"
                background-color="white-1"
                placeholder="Buscar (Ex: CPF ou nome)"
                prepend-inner-icon="mdi-magnify"
              ></v-text-field>
            </v-col>

            <v-col cols="3" sm="4" md="2">
              <report-options
                fileName="Doadores"
                :header="headerReport"
                :body="donators"
              />
            </v-col>
          </div>

          <v-divider></v-divider>
        </template>

        <template v-if="!donators.length">
          <div class="pt-16">
            <data-not-found
              v-if="!filterPristine"
              title="Doador não encontrado."
              :imgSrc="require('@/assets/partner.png')"
            />

            <data-not-found
              v-if="filterPristine"
              title="Você ainda não cadastrou nenhum doador, deseja cadastrar?"
              btnLabel="Cadastrar o primeiro doador PF"
              :btnAction="goToAddDonator"
              :imgSrc="require('@/assets/partner.png')"
              :canSeeAction="canRegisterDonator"
            />
          </div>
        </template>

        <template v-if="donators.length">
          <v-data-table
            :options.sync="dataTableOptions"
            :headers="headers"
            :items="donators"
            :server-items-length="tableInfo.totalItems"
            hide-default-footer
          >
            <template v-slot:item.cpf="{ item }">
              <template v-if="item.cpf">
                {{ item.cpf | VMask(masks.cpf) }}</template
              >
              <template v-else>
                {{ "Não informado" }}
              </template>
            </template>

            <!-- actions -->
            <template v-slot:item.id="{ item }">
              <donator-menu-item :id="item.id" type="PF" />
            </template>
          </v-data-table>
        </template>
      </div>

      <div v-if="donators.length">
        <pagination
          :currentPage="tableInfo.currentPage"
          :totalPages="tableInfo.totalPages"
          :itemsPerPage="tableInfo.itemsPerPage"
          @currentPageChanged="handleCurrentPageChange($event)"
          @itemsPerPageChanged="handleItemsPerPageChange($event)"
        />
      </div>
    </div>
  </v-card>
</template>

<script>
  import { mapActions, mapGetters } from "vuex";

  import DataNotFound from "@/modules/core/views/components/DataNotFound";
  import DonatorMenuItem from "@/modules/donator/views/components/DonatorMenuItem";
  import Pagination from "@/modules/core/views/components/Pagination";
  import ReportOptions from "@/modules/core/views/components/ReportOptions";

  import MasksMixin from "@/modules/core/mixins/masksMixin";
  import TableHandler from "@/modules/core/mixins/tableHandler";

  export default {
    name: "DonatorsPF",

    components: {
      DataNotFound,
      DonatorMenuItem,
      Pagination,
      ReportOptions
    },

    mixins: [MasksMixin, TableHandler],

    data: () => ({
      donators: [],
      headerReport: [
        { header: "Nome", dataKey: "name" },
        { header: "CPF", dataKey: "cpf" },
        { header: "E-mail", dataKey: "email" }
      ],
      headers: [
        {
          text: "Nome",
          align: "start",
          value: "name"
        },
        { text: "CPF", value: "cpf" },
        { text: "E-mail", value: "email" },
        { value: "id", sortable: false }
      ]
    }),

    async mounted() {
      await this.fetchDonatorsPF();
    },

    computed: {
      ...mapGetters({
        userRoleId: "user/getUserRoleId",
        routes: "getRoutes"
      }),

      canRegisterDonator() {
        return this.routes?.some(
          route =>
            route.name === "new-donator" &&
            route?.meta?.permissions.includes(this.userRoleId)
        );
      },

      canDetailDonator() {
        return this.routes?.some(
          route =>
            route.name === "detail-donator" &&
            route?.meta?.permissions.includes(this.userRoleId)
        );
      },

      showFilters() {
        if (this.donators.length) {
          return true;
        }
        return !this.donators.length && !this.filterPristine;
      }
    },

    methods: {
      ...mapActions(["toggleSnackbar"]),
      ...mapActions("donator", ["getDonatorPF"]),

      async fetchDonatorsPF() {
        try {
          const filters = this.getPayloadData();
          const { data, meta } = await this.getDonatorPF(filters);
          this.donators = data;
          this.setTableInfo(meta);
        } catch (error) {
          this.toggleSnackbar({
            text: error?.data?.message ?? "Ocorreu um erro. Tente novamente",
            type: "error"
          });
        }
      },

      goToAddDonator() {
        this.$router.push({ name: "new-donator" });
      },

      updateData() {
        this.fetchDonatorsPF();
      }
    }
  };
</script>

<style lang="scss" scoped></style>
